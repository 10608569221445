/* You can add global styles to this file, and also import other style files */
@font-face {
    font-family: 'Lato';
    src: url('assets/fonts/lato-regular-webfont.woff2') format('woff2'),
        url('assets/fonts/lato-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}
body {
  font-family: "Lato";
}
.modal {
  .modal-dialog {
    .modal-content {
      display: block !important;
    }
  }
}
.pull-right {
  float: right !important;
}

.pull-left {
  float: left !important;
}
/* Importing Bootstrap SCSS file. */
// @import 'bootstrap/scss/bootstrap';
.dropdown-menu {
  max-height: 50vh !important;
  overflow-y: auto;
  overflow-x: hidden;
}

.dropdown-menu .dropdown-item.active {
  background-color: rgb(0 0 0 / 20%);
  color: black;
}
.tableHeight {
  min-height: 60vh;
}
